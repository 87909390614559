import { Routes } from '@angular/router';
import { LoginPage } from './login.component';

export const routes: Routes = [
  {
    path: '',
    component: LoginPage,
    title: 'Antecedentes Penales | Chiapas',
  },
];
