import { Routes } from '@angular/router';
import { ValidatePage } from './validate.component';

export const routes: Routes = [
  {
    path: 'validacionAntecedente/:foil',
    component: ValidatePage,
    title: 'Antecedentes Penales | Chiapas',
  },
];
