<header id="main-header">
  <figure class="d-block d-sm-none m-0">
    <img src="./assets/images/nav320.png" class="w-100">
  </figure>
  <figure class="d-none d-sm-block d-lg-none m-0">
    <img src="./assets/images/nav640.png" class="w-100">
  </figure>
  <figure class="d-none d-sm-none d-lg-block m-0">
    <img src="./assets/images/navXl.png" class="w-100">
  </figure>
</header>

<main id="main" class="py-4">
  <div class="container">
    <h1 class="text-center text-primary mb-5">Validación de <br>Constancia de No Antecedentes Penales</h1>

    <div class="col-lg-8 m-auto">
      <p class="fs-4 text-uppercase">FOLIO:
        <span class="d-block text-primary fw-semibold">{{foil}}</span>
      </p>

      <p class="fs-4 text-uppercase">OFICINA QUE EXPIDIÓ:
        <span class="d-block text-primary fw-semibold">00000001 PJECH MODULO PALACIO TUXTLA</span>
      </p>

      <p class="fs-4 text-uppercase">CONSTANCIA A NOMBRE DE:
        <span class="d-block text-primary fw-semibold">C. {{name}}</span>
      </p>

      <p class="fs-4 text-uppercase">DE FECHA:
        <span class="d-block text-primary fw-semibold">{{date | date: "YYYY-MM-dd"}}</span>
      </p>

      <p class="fs-4 text-uppercase">CON ANTECEDENTES:
        <span class="d-block text-primary fw-semibold">NO</span>
      </p>

      <p class="fs-4 text-uppercase">CADENA DE CERTIFICACION:
        <span class="d-block text-primary fw-semibold fs-6"
          style="overflow-wrap: break-word;">||0006043|bf1a|{{date.split(" ")[0]}}|{{date.split("
          ")[1]}}|HElKFhZJRVJ9NDAyMjg1MTl8FDAwEDAkMzKyMzh8NzMxOLQzOHwyMDIzLTAxLTA2VDE1OjIzOjI4
          SXNXAMVGNDDDAWMXAV3HMADWSCDCCAecdDWSDGGTTRFG66GBUKRFU3xQSkVDSF9NT0RV
          TI9EIE854VGCancOHNIINVGDASARTHBBNCDObohbewASBCBHECfe3nHUI76VVhUTuF8fZ==||</span>
      </p>
    </div>
  </div>
</main>

<footer id="main-footer">
  <figure class="d-block d-sm-none d-md-none d-lg-none m-0">
    <img src="./assets/images/footer320.png" class="w-100">
  </figure>
  <figure class="d-none d-sm-block d-md-none d-lg-none m-0">
    <img src="./assets/images/footer640.png" class="w-100">
  </figure>
  <figure class="d-none d-sm-none d-md-block d-lg-none m-0">
    <img src="./assets/images/footer1024.png" class="w-100">
  </figure>
  <figure class="d-none d-sm-none d-md-none d-lg-block m-0">
    <img src="./assets/images/footerXL.png" class="w-100">
  </figure>
</footer>
