<main id="main">
  <header id="main-header" class="text-center pt-5 pb-3 border-bottom border-4 border-primary">
    <figure class="d-inline-block m-0">
      <img src="./assets/images/pje.png">
    </figure>
  </header>

  <div class="container py-4">
    <form class="m-auto border" style="max-width: 400px;" autocomplete="off">
      <header class="p-3 text-bg-primary">
        <h1 class="text-center text-uppercase m-0 fs-3" style="font-family: serif;">Bienvenido</h1>
      </header>
      <main class="bg-white p-4">
        <input placeholder="Email" type="text" pInputText class="w-100 mb-4" autocomplete="off" />
        <input placeholder="Contraseña" type="password" pInputText class="w-100" autocomplete="off" />

        <div class="mt-4 text-end">
          <button class="btn btn-light border" [disabled]="true">Iniciar sesión</button>
        </div>
      </main>
    </form>

    <p class="m-0 text-center mt-3 fw-medium fs-6"><small>CONSEJO DE LA JUDICATURA</small></p>
  </div>
</main>